/* THEME SCSS */
$primaryColor: #1D3B5D;

#topbar-wrapper {
  position: fixed;
  width: 100%;
}
#sidebar-wrapper {
  background-color: $primaryColor;
  min-height: calc(100vh - 4rem);
  @media (max-width: 991px) {
    min-height: 100vh;
  }
  > div {
    background-color: $primaryColor;
    height: calc(100vh - 4rem);
  }
}
#subcontent-wrapper {
  height: calc(100vh - 4rem);
  #content-wrapper {
    > div {
      min-height: calc(100vh - 6rem - 1px);
    }
  }
}


.text-align-center {
  text-align: center;
}
.d-block {
  display: block;
}

.custom-scrollpanel {
  .p-scrollpanel-content{
    padding: 0;
  }
  .p-scrollpanel-wrapper {
    border-right: 9px solid #FFF;
    //background-color: #fff;
  }
  .p-scrollpanel-bar {
    background-color: var(--gray-500);
    opacity: 1;
    transition: background-color .3s;
  }
  .p-scrollpanel-bar:hover {
    background-color: var(--gray-800);
  }
}

#mobile-search {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  span, input {
    width: 100%;
  }
}

#app-signin-page {
  form .p-field {
    label {
      font-weight: bold;
    }
  }
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td{
  padding: .5rem
}

.p-picklist-search {
  .p-picklist-buttons {
    opacity: 0 !important;
  }

  .p-inputgroup {
    margin-bottom: 5px;
  }
}
.p-picklist{
  .p-picklist-item {
    p {
      margin: 0;
    }
  }
  .p-picklist-list {
    padding-top: 0;
    padding-bottom: 0;
    li {
      border-bottom: 1px solid #e9ecef !important;
      &:nth-child(2n){
        background-color: #fcfcfc;
      }
    }
  }
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.3rem 0.5rem;
}

.p-chips {
  .p-help {
    margin-top: 0px;
  }
}

#account-change-password {
  input[type="password"] {
    width: 100%;
  }
}

.adv-upload-file {
  .p-fileupload-content{
    padding: 0;
    .p-button-icon {
      width: 30px;
    }
  }
}

.p-fileupload-advanced {
  .p-fileupload-buttonbar{
    button:nth-child(2){
      background-color: #689F38;
      border: solid 1px #689F38;
    }
    button:nth-child(3){
      background-color: #c02929;
      border: solid 1px #c02929;
    }
  }
}